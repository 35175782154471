import React from "react";
import "./Home.css";
import { EstateCard } from "../estateCard/EstateCard";
import { PlaceTop } from "../Place/PlaceTop";
import { Navb } from "../Navigation/Navb";
import { NewItem } from "../NewestItem/NewItem";
import { Sectionss } from "../section/Sectionss";
import { Latest } from "../LatestListings/Latest";
import { Footer } from "../Footer/Footer";
import { Buttuns } from "../ButtunLink/Buttuns";
import { TopB } from "../TopButtuns/TopB";

export const Home = () => {
  return (
    <div id="men-div0">
      <PlaceTop />
      <Navb />
      {/* <Sectionss /> */}
      <br />
      <br />
      <br />
      <br />
      <br />
      <NewItem />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <Latest />
      <br />
      <br />
      <br />
      <br />
      <br />
      <EstateCard />
      <br />
      <br />
      <br />
      <br />
      <br />

      <Buttuns />
      <TopB />

      <Footer />
    </div>
  );
};
