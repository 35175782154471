import React from "react";
import "./NewItem.css";

export const NewItem = () => {
  return (
    <div id="divmencard-0">
      {/* <h1 id="place-Estate-h1">Newest item</h1>
      <a id="view-a1" href="">
        VIEW All{" "}
      </a> */}

      <div id="card-1">
        <img
          id="imgcard1"
          src="../newitemc/metanew11.png"
          height="149px"
          width="218px"
        />
        <h4 id="hh4-1">Red Halo..,   '฿0.69</h4>
        <p id="pp-1">Ethereum</p>
        <h4 id="hhh4-2">-131,-37</h4>
      </div>

      <div id="card-1">
        <img
          id="imgcard1"
          src="../newitemc/metanew22.jpg"
          height="149px"
          width="218px"
        />
        <h4 id="hh4-1">Beach Feet   '฿0.69</h4>
        <p id="pp-1">Polygon</p>
        <h4 id="hhh4-2">-131,-37</h4>
      </div>

      <div id="card-1">
        <img
          id="imgcard1"
          src="../newitemc/metanew33.jpg"
          height="149px"
          width="218px"
        />
        <h4 id="hh4-1">Rain Clod...   '฿0.69</h4>
        <p id="pp-1">Polygon</p>
        <h4 id="hhh4-2">-131,-37</h4>
      </div>

      <div id="card-1">
        <img
          id="imgcard1"
          src="../newitemc/metanew44.jpg"
          height="149px"
          width="218px"
        />
        <h4 id="hh4-1">Clody city'฿0.69</h4>
        <p id="pp-1">Polygon</p>
        <h4 id="hhh4-2">-131,-37</h4>
      </div>

      <div id="card-1">
        <img
          id="imgcard1"
          src="../newitemc/metanew55.jpg"
          height="149px"
          width="218px"
        />
        <h4 id="hh4-1">Poker chip '฿0.69</h4>
        <p id="pp-1">Polygon</p>
        <h4 id="hhh4-2">-131,-37</h4>
      </div>

      <div id="card-1">
        <img
          id="imgcard1"
          src="../newitemc/metanew11.png"
          height="149px"
          width="218px"
        />
        <h4 id="hh4-1"> '฿0.69</h4>
        <p id="pp-1">Polygon</p>
        <h4 id="hhh4-2">-131,-37</h4>
      </div>
      <div id="card-1">
        <img
          id="imgcard1"
          src="../newitemc/metanew22.jpg"
          height="149px"
          width="218px"
        />
        <h4 id="hh4-1"> cloud men'฿0.69</h4>
        <p id="pp-1">Polygon</p>
        <h4 id="hhh4-2">-131,-37</h4>
      </div>

      <div id="card-1">
        <img
          id="imgcard1"
          src="../newitemc/metanew33.jpg"
          height="149px"
          width="218px"
        />
        <h4 id="hh4-1">Avter....   '฿0.69</h4>
        <p id="pp-1">Ploygon</p>
        <h4 id="hhh4-2">-131,-37</h4>
      </div>
    </div>
  );
};
