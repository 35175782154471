import React from "react";
import "./PlaceTop.css";

export const PlaceTop = () => {
  return (
    <div>
      <img
        id="img-template"
        src="marketPlace/metaimgv1.jpg"
        width="100%"
        height="500px"
      />

      <div class="sign">
        <span class="fast-flicker">L </span>ive<span class="flicker">M</span>eta
        <span class="flicker">v</span>erse
      </div>

      <div class="title">
        <h1>
          Live
          <br />
          Metaverse
        </h1>
      </div>
    </div>
  );
};
