import React from "react";
import "./Buttuns.css";

import { Button } from "react-bootstrap";

export const Buttuns = () => {
  return (
    <div>
      <Button id="buttn">Newest items1</Button>
      <br />
      <Button id="buttn-2">Newest items2</Button>
      <br />
      <Button id="buttn-3">Newest items3</Button>
    </div>
  );
};
