import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Home } from "./componets/hompage/Home";
// import { BrowserRouter, Routes, Route ,links} from "react-router-dom";
// import { Counter } from "./components/Counter";
// import { ProductsList } from "./components/ProductsList";
// import { BikeList } from "./components/BikeList";
// import { NavLib } from "react-navlib-pickupbiz";
function App() {
  return (
    <div className="App">
      <Home />
    </div>
  );
}

export default App;
