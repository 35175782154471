import { Button } from "react-bootstrap";
import React from "react";
import "./Navb.css";

export const Navb =()=>{
return(
    <div id="navb-men0">
        {/* <nav id="nav-men">
         <a id="navb" href="">BuyLAND </a>
         <a id="navb" href="">LEADERBORD</a>
         <a id="navb" href="">TEAM</a>
         <a id="navb" href="">CHARITY</a>
         <a id="navb" href="">MyLANDS</a>
         <a id="navb" href="">FAQ</a>
         <a id="navb" href="">LOGIN/SINGUP</a>
        </nav> */}
         <nav class="">
        <a id="naw-a" href="">HOME</a>
        <a id="naw-a" href="">BuyMETA </a>
        <a id="naw-a" href="">LEADERBORD</a>
        <a id="naw-a" href="">TEAM</a>
        <a id="naw-a" href=""> CUSTOMER SERVICE </a>
        <a id="naw-a" href="">META LANDS</a>
        <a id="naw-a" href="">LOGIN/SINGUP</a>
        <input id="srchbox" type="search" placeholder="search"></input>
        <Button id="srchbox-B" >search</Button>
    </nav>
        



    </div>

);

};