import React from "react";
import "./EstateCard.css";
// import { Row, Col, img } from "react-bootstrap";

export const EstateCard = () => {
  return (
    <div id="divmencard-0">
      {/* <h1 id="place-Estate-h1">Place & Estate</h1>
      <a id="view-a1" href="">
        VIEW All{" "}
      </a> */}

      <div id="card-1">
        <img
          id="imgcard1"
          src="../statecard/metamap1.jpg"
          height="149px"
          width="218px"
        />
        <h4 id="hh4-1">Dargon-city.., 💰9,500</h4>
        <p id="pp-1">Ethereum</p>
        <h4 id="hhh4-2">-131,-37</h4>
      </div>

      <div id="card-1">
        <img
          id="imgcard1"
          src="../statecard/metamap22.png"
          height="149px"
          width="218px"
        />
        <h4 id="hh4-1">Dargon-city.., 💰9,500</h4>
        <p id="pp-1">Ethereum</p>
        <h4 id="hhh4-2">-131,-37</h4>
      </div>

      <div id="card-1">
        <img
          id="imgcard1"
          src="../statecard/metamap33.png"
          height="149px"
          width="218px"
        />
        <h4 id="hh4-1">Dargon-city.., 💰9,500</h4>
        <p id="pp-1">Ethereum</p>
        <h4 id="hhh4-2">-131,-37</h4>
      </div>

      <div id="card-1">
        <img
          id="imgcard1"
          src="../statecard/metamap44.png"
          height="149px"
          width="218px"
        />
        <h4 id="hh4-1">Dargon-city.., 💰9,500</h4>
        <p id="pp-1">Ethereum</p>
        <h4 id="hhh4-2">-131,-37</h4>
      </div>

      <div id="card-1">
        <img
          id="imgcard1"
          src="../statecard/metamap55.png"
          height="149px"
          width="218px"
        />
        <h4 id="hh4-1">Dargon-city.., 💰9,500</h4>
        <p id="pp-1">Ethereum</p>
        <h4 id="hhh4-2">-131,-37</h4>
      </div>

      <div id="card-1">
        <img
          id="imgcard1"
          src="../statecard/metamap66.png"
          height="149px"
          width="218px"
        />
        <h4 id="hh4-1">Dargon-city.., 💰9,500</h4>
        <p id="pp-1">Ethereum</p>
        <h4 id="hhh4-2">-131,-37</h4>
      </div>
      <div id="card-1">
        <img
          id="imgcard1"
          src="../statecard/metamap77.png"
          height="149px"
          width="218px"
        />
        <h4 id="hh4-1">Dargon-city.., 💰9,500</h4>
        <p id="pp-1">Ethereum</p>
        <h4 id="hhh4-2">-131,-37</h4>
      </div>

      <div id="card-1">
        <img
          id="imgcard1"
          src="../statecard/metamap88.png"
          height="149px"
          width="218px"
        />
        <h4 id="hh4-1">Dargon-city.., 💰9,500</h4>
        <p id="pp-1">Ethereum</p>
        <h4 id="hhh4-2">-131,-37</h4>
      </div>
    </div>
  );
};
