import React from "react";
import { Button } from "react-bootstrap";
import "./TopB.css";

export const TopB = () => {
  return (
    <div>
      <Button id="metaB1">metaLand1</Button>
      <br />
      <Button id="metaB2">metaLand2</Button>
      <br />
      <Button id="metaB3">metaLand3</Button>
      <br />
      <Button id="metaB4">metaLand4</Button>
      <br />
    </div>
  );
};
